<template>
  <aside class="right-side">
    <div class="background">
      <DotsTexture class="dots" />
      <div class="circle" />
    </div>
    <div class="foreground">
      <div
        ref="card"
        class="card"
        tabindex="0"
        @focusin="cardFocusIn"
        @focusout="cardFocusOut"
        @keydown="keydown"
      >
        <VueSlickCarousel
          v-if="items.length"
          v-bind="settings"
          ref="carousel"
          @beforeChange="beforeSlideChange"
        >
          <div v-for="(item, i) in items" :key="i" class="slide">
            <div class="slide-content">
              <span class="top-text">
                Les plus consultés
              </span>
              <span :title="item.title" class="company-title">
                {{ item.title }}
              </span>
              <div class="company-content">
                <Skeleton />
                <Skeleton />
                <div v-if="item.employees !== null && item.employees !== undefined" class="company-param employees" style="padding-left: 7px;">
                  <UserIcon class="icon" />
                  <div class="title-n-value">
                    <span> Nombre d’employés</span>
                    <span>{{ item.employees }}</span>
                  </div>
                </div>
                <div v-if="item.turnover !== null && item.turnover !== undefined" class="company-param turnover" style="padding-left: 7px;">
                  <DollarIcon class="icon" />
                  <div class="title-n-value">
                    <span> Dernier C.A</span>
                    <span>{{ numberSuffix(item.turnover, 0, true) }}</span>
                  </div>
                </div>
              </div>
              <CButton
                :ref="`button${i}`"
                class="button"
                border-type="rounded"
                tabindex="-1"
                :to="item.link"
                @focus.native="e => buttonFocused(e,i)"
                @click.native.prevent.stop
              >
                consulter la fiche
                <NextIcon class="next-icon" />
              </CButton>
            </div>
          </div>

          <template #prevArrow="arrowOption">
            <div class="custom-arrow prev">
              <NextIcon />
              {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
            </div>
          </template>

          <template #nextArrow="arrowOption">
            <div class="custom-arrow next">
              <NextIcon />
              {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
            </div>
          </template>
        </VueSlickCarousel>
      </div>
    </div>
  </aside>
</template>

<script>
import slickCarousel from '@/mixins/slickCarousel'
import DotsTexture from '@/assets/svg/parts/dots.svg'
import DollarIcon from '@/assets/svg/icons/dollar.svg'
import UserIcon from '@/assets/svg/icons/user.svg'
import CButton from '@/components/common/CButton.vue'
import NextIcon from '@/assets/svg/arrow-right.svg'
import Skeleton from '@/components/common/Skeleton.vue'
import numbers from '~/mixins/numbers'
import deviceInfo from '@/mixins/deviceInfo'

export default {
  name: 'FeaturesRightSide',
  components: {
    DotsTexture,
    CButton,
    UserIcon,
    NextIcon,
    DollarIcon,
    Skeleton
  },
  mixins: [slickCarousel, numbers, deviceInfo],
  props: {
    items: {
      default: () => [
        { title: 'Google Paris', employees: '500 +', turnover: 108932535 }
      ]
    }
  },
  data () {
    return {
      settings: {
        dotsClass: 'slick-dots custom-dot-class',
        edgeFriction: 0.35,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        lazyLoad: 'progressive',
        slidesToScroll: 1,
        initialSlide: 0,
        focusOnSelect: false,
        swipe: false,
        accesibility: true,
        autoplay: true,
        autoplaySpeed: 3000
      },
      tabNavigationActive: true,
      slide: 0,
      focused: false
    }
  },
  methods: {
    buttonFocused (e, i) {
      e.preventDefault()
      e.stopPropagation()
    },
    cardFocusOut () {
      this.focused = false
    },
    cardFocusIn () {
      this.focused = true
    },
    keydown (e) {
      if (this.focused) {
        if (/arrowleft/i.test(e.key)) {
          this.$refs.carousel.prev()
          this.$refs.card.focus()
        } else if (/arrowright/i.test(e.key)) {
          this.$refs.carousel.next()
          this.$refs.card.focus()
        } else if (/enter/i.test(e.key)) {
          e.stopPropagation()
          e.preventDefault()
          this.$refs[`button${this.slide}`].$el.click()
        }
      }
    },
    beforeSlideChange (old, i) {
      this.slide = i
    }
  }
}
</script>

<style lang="scss" scoped>
.right-side {
  position: relative;
  height: 100%;

  .background {
    position: absolute;
    inset: 0;

    .circle {
      position: absolute;
      right: -78px;
      top: 94px;
      width: 190px;
      height: 190px;
      background: var(--brand);
      border-radius: 1000px;

      &::after {
        content: '';
        position: absolute;
        width: 322px;
        height: 322px;
        border: 3px solid var(--brand);
        border-radius: 1000px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .dots {
      position: absolute;
      left: 53px;
      bottom: 198px;
    }
  }

  .card {
    position: absolute;
    right: -3px;
    bottom: 0;
    background: #ffffffb2;
    backdrop-filter: blur(10px);
    max-width: 355px;
    max-height: 483px;
    height: 100vh;
    width: 100vw;
    border-radius: 30px;
    font-family: Poppins, sans-serif;
    box-shadow: 0px 100px 80px rgba(130, 160, 177, 0.07), 0px 64.8148px 46.8519px rgba(130, 160, 177, 0.0531481),
      0px 38.5185px 25.4815px rgba(130, 160, 177, 0.0425185), 0px 20px 13px rgba(130, 160, 177, 0.035),
      0px 8.14815px 6.51852px rgba(130, 160, 177, 0.0274815);

    transition: all 0.25s ease;
    outline-offset: 6px;

    &:focus-visible {
      outline-offset: -1px;
      outline: 1px var(--hovered-brand) solid;

      .slide-content .button {
        outline-offset: -2px;
        outline: 1px var(--white) solid;
      }
    }
  }

  .slide {
    height: 100vh;
    max-width: 355px;
    max-height: 483px;
  }

  .custom-arrow {
    --svg-color: var(--title);
    transform-origin: center;
    width: 26px;
    height: 26px;
    --max-offset-x: 6px;
    --rotate: rotate(0deg);
    --base-offset-x: 16px;
    --max-offset-y: 0px;
    --rotate: rotate(0deg);
    --base-offset-y: 0px;
    opacity: 1;
    transition: all 0.25s ease;

    &.slick-disabled {
      opacity: 0;
      pointer-events: none;
    }

    &.prev {
      --rotate: rotateZ(180deg);
    }

    svg {
      transition: all 0.25s ease;
      width: inherit;
      height: inherit;
    }

    &::before {
      content: unset;
    }

    &.prev svg {
      transform: rotateZ(180deg) translateX(var(--base-offset-x));
    }

    &.next svg {
      transform: translateX(var(--base-offset-x));
    }

    &:hover svg {
      animation: little-jump 2s linear 0s infinite normal backwards;
    }
  }

  .slide-content {
    padding: 50px 40px 37px;
    display: grid;
    height: 100%;
    grid-template-rows: max-content max-content auto max-content;
    letter-spacing: 0.04em;
    font-weight: 600;

    .top-text {
      color: var(--paragraph);
      text-transform: uppercase;
      font-weight: 600;
      font-size: var(--font-size-normal-s2);
      line-height: var(--line-height-normal-s1);
      text-align: center;
    }

    .company-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 42px 0 0;
      font-weight: 600;
      font-size: var(--font-size-large-l1);
      line-height: var(--line-height-large-l1);
      text-align: center;
      letter-spacing: 0;
    }

    .button {
      height: 64px;
      width: 100%;
      letter-spacing: inherit;
      text-transform: uppercase;
      font-size: var(--font-size-normal);
      line-height: var(--line-height-normal-l1);
      --svg-color: var(--white);
      max-width: 275px;

      svg {
        margin-left: 8px;
        width: 24px;
        height: 24px;
      }

      &:hover {
        svg {
          animation: little-jump 2s ease 0s infinite normal forwards;
          --base-offset-x: 0px;
          --max-offset-x: 5px;
        }
      }
    }

    .company-content {
      & > .skeleton:first-child {
        height: 10px;
      }

      & > .skeleton:nth-child(2) {
        height: 10px;
        width: 60%;
        margin-top: 10px;
        margin-bottom: 36px;
      }
      .company-param {
        justify-content: flex-start;
        align-items: center;
        display: flex;
        &.turnover {
          margin-top: 10px;
          .icon {
            height: 24.75px;
          }
        }
        &.employees .icon {
          height: 21px;
        }
        .icon {
          width: 19px;
          margin-right: 19px;
          --svg-color: var(--brand);
        }
        .title-n-value {
          display: flex;
          flex-direction: column;
          gap: 4px;
          & > *:first-child {
            font-size: var(--font-size-small-s2);
            line-height: var(--line-height-small-s1);
            color: var(--paragraph);

            text-transform: uppercase;
          }
          & > *:last-child {
            font-size: var(--font-size-normal-s2);
            line-height: var(--line-height-normal-s1);
          }
        }
      }
    }
  }

  .foreground {
    position: absolute;
    inset: 0;
  }

  @media (max-width: 1340px) {
    margin-right: 80px;
    .background .dots {
      left: -30px;
    }
  }

  @include tablet-landscape {
    position: relative;
    margin-top: 195px;
    margin-right: 0px;

    .foreground {
      position: relative;
      @include flex-line-center;

      .card {
        position: relative;
      }
    }

    .background {
      .circle {
        right: 32px;
        top: -56px;
      }

      .dots {
        left: 38px;
      }
    }
  }
}
</style>

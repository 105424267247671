<template>
  <div class="skeleton" />
</template>

<script>
export default {
  name: 'Skeleton'

}
</script>

<style lang="scss" scoped>
.skeleton {
  background: var(--separator);
  width: 100%;
  height: 1em;
  display: block;
}
</style>
